export function getVipTierRoutePath() {
  return '/vip/vip-tier'
}

export function getVipCenterRoutePath() {
  return '/vip/vip-center'
}

export function getVipSettingRoutePath() {
  return '/vip/vip-setting'
}
